import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Vuelidate from 'vuelidate'
import '../src/assets/global.scss'
import '/src/assets/animation.scss'
import * as VueGoogleMaps from 'vue2-google-maps'
import vClickOutside from 'v-click-outside'
import VueMask from 'v-mask'
import VueI18n from 'vue-i18n'
import EN from './locale/en.json'
import RU from './locale/ru.json'
import {getCookie, setCookie} from "@/utils/cookie";

if(!getCookie('locale')) {
  setCookie('locale', 'ru')
}

Vue.use(Vuelidate).use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyAHj30xFmamhZLDcBL2KMkcjwGEaByI3Vs',
    language: getCookie('locale'),
}}).use(vClickOutside).use(VueMask).use(VueI18n)

Vue.config.productionTip = false

const i18n = new VueI18n({
  locale: getCookie('locale'),
  silentTranslationWarn: true,
  messages: {
    en: EN,
    ru: RU
  }
});

new Vue({
  router,
  store,
  render: h => h(App),
  i18n,
}).$mount('#app')
