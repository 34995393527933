import { usersApi } from '@/api'

export default {
    state: {
        ecoActivists: [],
        membersData: [],
        usersData: null,
        membersMeta: {},
        confHistory: [],
        usersLoader: false
    },

    getters: {
        ecoActivists: (state) => state.ecoActivists,
        membersData: (state) => state.membersData,
        usersData: (state) => state.usersData,
        getMembersMeta: (state) => state.membersMeta,
        getConfHistory: (state) => state.confHistory,
        getUsersLoader: (state) => state.usersLoader
    },

    mutations: {
        setEcoActivists(state, payload) {
            state.ecoActivists = payload
        },
        setMembersData(state, payload) {
            state.membersData = payload
        },
        setUsersData(state, payload) {
            state.usersData = payload
        },
        setMembersMeta(state, payload) {
            state.membersMeta = payload
        },
        setConfHistory(state, payload) {
            state.confHistory = payload
        },
        setUsersLoader(state, payload) {
            state.usersLoader = payload
        }
    },

    actions: {
        users(state, payload) {
            return new Promise((resolve, reject) => {
                usersApi.getUsers(payload).then((res) => {
                    resolve(res.data)
                }).catch((err) => {
                    reject(err)
                })
            })
        },

        userId({ commit }, payload) {
            return new Promise((resolve, reject) => {
                usersApi.getUserId(payload).then((res) => {
                    commit('setUsersData', res.data)
                    resolve(res.data)
                }).catch((err) => {
                    reject(err)
                })
            })
        },

        userAccept(state, payload) {
            return new Promise((resolve, reject) => {
                usersApi.userAccept(payload).then((res) => {
                    resolve(res.data)
                }).catch((err) => {
                    reject(err)
                })
            })
        },

        userDecline(state, payload) {
            return new Promise((resolve, reject) => {
                usersApi.userDecline(payload).then((res) => {
                    resolve(res.data)
                }).catch((err) => {
                    reject(err)
                })
            })
        },

        addUser(state, payload) {
            return new Promise((resolve, reject) => {
                usersApi.addUser(payload).then((res) => {
                    resolve(res.data)
                }).catch((err) => {
                    reject(err)
                })
            })
        },

        editUser(state, payload) {
            return new Promise((resolve, reject) => {
                usersApi.editUser(payload).then((res) => {
                    resolve(res.data)
                }).catch((err) => {
                    reject(err)
                })
            })
        },

        userConfHistory({ commit }, payload) {
            return new Promise((resolve, reject) => {
                usersApi.userConfHistory(payload).then((res) => {
                    commit('setConfHistory', res.data.data)
                    resolve(res.data)
                }).catch((err) => {
                    reject(err)
                })
            })
        },
    }
}
