import HTTP from '@/api/http'

const user = (responseHandler, errorHandler) => {
  return HTTP.get('/me').then(responseHandler).catch(errorHandler)
}

const update = (payload, responseHandler, errorHandler) => {
  return HTTP.put('/me', payload).then(responseHandler).catch(errorHandler)
}

const deleteAccount = (payload, responseHandler, errorHandler) => {
  return HTTP.post('/auth/delete-account', payload).then(responseHandler).catch(errorHandler)
}

const login = (payload, responseHandler, errorHandler) => {
  return HTTP.post('/auth/signin', payload).then(responseHandler).catch(errorHandler)
}

const logout = (responseHandler, errorHandler) => {
  return HTTP.post('/auth/signout').then(responseHandler).catch(errorHandler)
}

const auth = (payload, responseHandler, errorHandler) => {
  return HTTP.post('/auth/signup', payload).then(responseHandler).catch(errorHandler)
}

const reset = (payload, responseHandler, errorHandler) => {
  return HTTP.post('/auth/reset', payload).then(responseHandler).catch(errorHandler)
}

const orderTypes = (responseHandler, errorHandler) => {
  return HTTP.get('/order-types').then(responseHandler).catch(errorHandler)
}

const regions = (responseHandler, errorHandler) => {
  return HTTP.get('/regions').then(responseHandler).catch(errorHandler)
}

const addRegion = (payload, responseHandler, errorHandler) => {
  return HTTP.post('/regions', payload).then(responseHandler).catch(errorHandler)
}

const updateRegions = (payload, responseHandler, errorHandler) => {
  return HTTP.patch(`/regions/${payload.id}`, {
    image: payload.image
  }).then(responseHandler).catch(errorHandler)
}

const upload = (payload, responseHandler, errorHandler) => {
  return HTTP.post('/upload', payload).then(responseHandler).catch(errorHandler)
}

const uploadAvatar = (payload, responseHandler, errorHandler) => {
  return HTTP.post('/upload/avatar', payload, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  }).then(responseHandler).catch(errorHandler)
}

const changePassword = (payload, responseHandler, errorHandler) => {
  return HTTP.post('/auth/change-password', payload).then(responseHandler).catch(errorHandler)
}

const getOrders = (params,responseHandler, errorHandler) => {
  return HTTP.get(`/orders?sort=${params.sort}`).then(responseHandler).catch(errorHandler)
}

const restoreAccount = (payload, responseHandler, errorHandler) => {
  return HTTP.post('/auth/restore-account', payload).then(responseHandler).catch(errorHandler)
}

const getNotifications = (responseHandler, errorHandler) => {
  return HTTP.get('/me/notifications').then(responseHandler).catch(errorHandler)
}

const notificationsRead = (params, responseHandler, errorHandler) => {
  return HTTP.post('/me/notifications/mark-read', params).then(responseHandler).catch(errorHandler)
}

const roleHistory = (responseHandler, errorHandler) => {
  return HTTP.get('/me/role-history').then(responseHandler).catch(errorHandler)
}

const deleteUnauthorized = (params, responseHandler, errorHandler) => {
  return HTTP.post('/auth/delete-unauthorized', params).then(responseHandler).catch(errorHandler)
}

export default {
  user,
  login,
  logout,
  auth,
  reset,
  update,
  deleteAccount,
  orderTypes,
  regions,
  addRegion,
  updateRegions,
  upload,
  uploadAvatar,
  changePassword,
  getOrders,
  restoreAccount,
  getNotifications,
  notificationsRead,
  roleHistory,
  deleteUnauthorized
}
