import HTTP from '@/api/http'

const getRegions = (params, responseHandler, errorHandler) => {
    return HTTP.get(`/regions/rating`, {
        params: params,
    }).then(responseHandler).catch(errorHandler)
}

const getRatingRegions = (params, responseHandler, errorHandler) => {
    return HTTP.get(`/rr/specs/rating`, {
        params: params,
    }).then(responseHandler).catch(errorHandler)
}

const saveExcel = (payload, responseHandler, errorHandler) => {
    return HTTP.post(`/rr/specs/excel-export?year=${payload.year}`).then(responseHandler).catch(errorHandler)
}

const downloadIndicators = (payload, responseHandler, errorHandler) => {
    return HTTP.post(`/rr/specs/excel-import`, payload).then(responseHandler).catch(errorHandler)
}
const exportExcel = (payload, responseHandler, errorHandler) => {
    return HTTP.post(`/rr/specs/excel-export-base?year=${payload.year}`, payload).then(responseHandler).catch(errorHandler)
}
const saveSummaryExcel = (payload, responseHandler, errorHandler) => {
    return HTTP.post(`/rr/specs/excel-export-rating?year=${payload.year}`, payload).then(responseHandler).catch(errorHandler)
}

export default {
    getRegions,
    getRatingRegions,
    saveExcel,
    downloadIndicators,
    exportExcel,
    saveSummaryExcel
}
