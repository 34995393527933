export const setCookie = (name, value, days) => {
    let exDate = new Date();
    exDate.setDate(exDate.getDate() + days);
    let c_value = escape(value) + ((days == null) ? "" : "; expires=" + exDate.toUTCString());

    document.cookie = name + "=" + c_value;

}
export const getCookie = (name) => {
    const newName = name + "=";
    const allCookies = document.cookie.split(';');

    for(let i = 0; i < allCookies.length; i++) {
        let item = allCookies[i];
        while (item.charAt(0) === ' ') item = item.substring(1, item.length);
        if (item.indexOf(newName) === 0) return item.substring(newName.length, item.length);
    }

    return null;
}
export const deleteCookie = (name) => {
    document.cookie = name+'=; Max-Age=-99999999;';
}
