import Vue from 'vue'
import Vuex from 'vuex'
import { authApi } from '@/api'
import { deleteCookie, setCookie } from "@/utils/cookie";
import userStore from './user'
import ordersStore from "./orders";
import usersStore from "./users"
import supportStore from "./support";
import regionsStore from "./regions"
import adminStore from "./admin"
import eventsStore from "./events"
import esgStore from "./esg"
import documentsStore from "./documents"
import AdminRegionStore from "./adminRegion"

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
      auth: false,
      userData: null,
      visibleSideBar: false,
      mapSortBy: {id: 0, text: 'Все'},
      authForm: {
          email: '',
          password: '',
          repeatPassword: '',
          authCode: '',
      },
      recoveryForm: {
          email: '',
          code: '',
          password: '',
          copyPassword: ''
      },
      orderTypes: [],
      regions: [],
      userRating: ['beginner', 'advanced','experienced','specialist','expert','professional'],
      notifications: [],
      roleRejectedHistory: []
  },

  getters: {
      isAuth: (state) => state.auth,
      getUser: (state) => state.userData,
      isVisibleSideBar: (state) => state.visibleSideBar,
      getOrderTypes: (state) => state.orderTypes,
      getRegions: (state) => state.regions,
      getAuthForm: (state) => state.authForm,
      getNotifications: (state) => state.notifications,
      getRoleRejectedHistory: (state) => state.roleRejectedHistory,
      getMapSortBy: (state) => state.mapSortBy
  },

  mutations: {
      changeVisibleSideBar(state) {
          state.visibleSideBar = !state.visibleSideBar
      },

      setUserData(state, payload) {
          state.userData = payload
      },

      setAuth(state, payload) {
          state.auth = payload
      },

      setOrderTypes(state, payload) {
          state.orderTypes = payload
      },

      setRegions(state, payload) {
          state.regions = payload
      },

      clearAuthForm(state) {
          state.authForm = {}
      },

      setNotifications(state, payload) {
          state.notifications = payload
      },

      setRoleRejectedHistory(state, payload) {
          state.roleRejectedHistory = payload
      },
      setMapSort(state, payload) {
          state.mapSortBy = payload
      }
  },

  actions: {
      user({ commit }) {
          return new Promise((resolve, reject) => {
              authApi.user().then((res) => {
                  commit('setUserData', res.data)
                  resolve(res.data)
              }).catch((err) => {
                  if(err.response.status === 401) {
                      deleteCookie('authToken')
                      window.location.reload()
                  }
                  reject(err)
              })
          })
      },

      login({ commit }, payload) {
          return new Promise((resolve, reject) => {
              authApi.login(payload).then((res) => {
                  if (res.data?.user && !res.data?.user?.mustChangePassword) {
                      commit('setAuth', true)
                  }
                  setCookie('authToken', res.data.token)
                  resolve(res)
              }).catch((err) => {
                  reject(err)
              })
          })
      },

      restoreAccount(state, payload) {
          return new Promise((resolve, reject) => {
              authApi.restoreAccount(payload).then((res) => {
                  resolve(res)
              }).catch((err) => {
                  reject(err)
              })
          })
      },

      reqLogout({ commit }) {
          return new Promise((resolve, reject) => {
              authApi.logout().then((res) => {
                  deleteCookie('authToken')
                  commit('setAuth', false)
                  resolve(res)
              }).catch((err) => {
                  reject(err)
              })
          })
      },

      reqAuth(store, payload) {
          return new Promise((resolve, reject) => {
              authApi.auth(payload).then((res) => {
                  resolve(res)
              }).catch((err) => {
                  reject(err)
              })
          })
      },

      reset(store, payload) {
          return new Promise((resolve, reject) => {
              authApi.reset(payload).then((res) => {
                  resolve(res)
              }).catch((err) => {
                  reject(err)
              })
          })
      },

      update(store, payload) {
          return new Promise((resolve, reject) => {
              authApi.update(payload).then((res) => {
                  resolve(res)
              }).catch((err) => {
                  reject(err)
              })
          })
      },

      reqDeleteAccount({ commit }) {
          return new Promise((resolve, reject) => {
              authApi.deleteAccount().then((res) => {
                  deleteCookie('authToken')
                  commit('setAuth', false)
                  resolve(res)
              }).catch((err) => {
                  reject(err)
              })
          })
      },

      reqOrderTypes({ commit }) {
          return new Promise((resolve, reject) => {
              authApi.orderTypes().then((res) => {
                  commit('setOrderTypes', res.data.data)
                  resolve(res)
              }).catch((err) => {
                  reject(err)
              })
          })
      },

      reqRegions({ commit }) {
          return new Promise((resolve, reject) => {
              authApi.regions().then((res) => {
                  commit('setRegions', res.data.data)
                  resolve(res)
              }).catch((err) => {
                  reject(err)
              })
          })
      },

      addRegion(state, payload) {
          return new Promise((resolve, reject) => {
              authApi.addRegion(payload).then((res) => {
                  resolve(res)
              }).catch((err) => {
                  reject(err)
              })
          })
      },

      updateRegions(state, payload){
          return new Promise((resolve, reject) => {
              authApi.updateRegions(payload).then((res) => {
                  resolve(res)
              }).catch((err) => {
                  reject(err)
              })
          })
      },

      reqUpload(store, payload) {
          const formData = new FormData()
          formData.append('file', payload.file)
          formData.append('type', payload.type)
          return new Promise((resolve, reject) => {
              authApi.upload(formData).then((res) => {
                  resolve(res)
              }).catch((err) => {
                  reject(err)
              })
          })
      },

      reqUploadAvatar(store, payload) {
          return new Promise((resolve, reject) => {
              const formData = new FormData()
              formData.append('file', payload)
              formData.append('type', 'avatar')
              authApi.uploadAvatar(formData).then((res) => {
                  resolve(res.data)
              }).catch((err) => {
                  reject(err)
              })
          })
      },

      notifications({ commit }) {
          return new Promise((resolve, reject) => {
              authApi.getNotifications().then((res) => {
                  commit('setNotifications', res.data.data)
                  resolve(res)
              }).catch((err) => {
                  reject(err)
              })
          })
      },

      notificationsRead(store, payload) {
          return new Promise((resolve, reject) => {
              authApi.notificationsRead(payload).then((res) => {
                  resolve(res)
              }).catch((err) => {
                  reject(err)
              })
          })
      },

      roleHistory({commit}, payload) {
          return new Promise((resolve, reject) => {
              authApi.roleHistory(payload).then((res) => {
                  commit('setRoleRejectedHistory', res.data.data)
                  resolve(res.data.data)
              }).catch((err) => {
                  reject(err)
              })
          })
      },

      deleteUnauthorized(store, payload) {
          return new Promise((resolve, reject) => {
              authApi.deleteUnauthorized(payload).then((res) => {
                  resolve(res.data.data)
              }).catch((err) => {
                  reject(err)
              })
          })
      },
  },
  modules: {
      userStore,
      ordersStore,
      usersStore,
      supportStore,
      regionsStore,
      adminStore,
      eventsStore,
      esgStore,
      documentsStore,
      AdminRegionStore
  }
})
