import { regionsApi } from "@/api"

export default {
    state: {
        dataRegions: [],
        ratingRegions: []
    },

    getters: {
        dataRegions: (state) => state.dataRegions,
        getRatingRegions: (state) => state.ratingRegions,
    },

    mutations: {
        setDataRegions(state, payload) {
            state.dataRegions = payload
        },
        setRatingRegions(state, payload) {
            state.ratingRegions = payload
        },
    },

    actions: {
        regions(state, payload) {
            return new Promise((resolve, reject) => {
                regionsApi.getRegions(payload).then((res) => {
                    resolve(res.data)
                }).catch((err) => {
                    reject(err)
                })
            })
        },
        ratingRegions(state, payload) {
            return new Promise((resolve, reject) => {
                regionsApi.getRatingRegions(payload).then((res) => {
                    resolve(res.data)
                }).catch((err) => {
                    reject(err)
                })
            })
        },
        saveExcel(state, payload) {
            return new Promise((resolve, reject) => {
                regionsApi.saveExcel(payload).then((res) => {
                    resolve(res.data)
                }).catch((err) => {
                    reject(err)
                })
            })
        },
        saveSummaryExcel(state, payload) {
            return new Promise((resolve,reject) => {
                regionsApi.saveSummaryExcel(payload).then((res) => {
                    resolve(res.data)
                }).catch((err) => {
                    reject(err)
                })
            })
        },
        downloadIndicators(state, payload) {
            return new Promise((resolve, reject) => {
                regionsApi.downloadIndicators(payload).then((res) => {
                    resolve(res.data)
                }).catch((err) => {
                    reject(err)
                })
            })
        },
        exportExcel(state,payload) {
            return new Promise ((resolve, reject) => {
                regionsApi.exportExcel(payload).then((res) => {
                    resolve(res.data)
                }).catch((err) => {
                    reject(err)
                })
            })
        }
    }
}
