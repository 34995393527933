import { authApi } from '@/api'

export default {
    state: {
        // auth: false,
    },

    getters: {
        // isAuth: (state) => state.auth,
    },

    mutations: {
        // changeVisibleSideBar(state) {
        //     state.visibleSideBar = !state.visibleSideBar
        // },
    },

    actions: {
        changePassword(store, payload) {
            return new Promise((resolve, reject) => {
                authApi.changePassword(payload).then((res) => {
                    resolve(res.data)
                }).catch((err) => {
                    reject(err)
                })
            })
        },
    }
}
