import HTTP from '@/api/http'

const getYears = (payload, responseHandler, errorHandler) => {
    return HTTP.get(`/years`).then(responseHandler).catch(errorHandler)
}

const addYears = (payload, responseHandler, errorHandler) => {
    return HTTP.post(`/years`, payload).then(responseHandler).catch(errorHandler)
}

const editYears = (payload, responseHandler, errorHandler) => {
    return HTTP.patch(`/years/${payload.itemId}`, payload).then(responseHandler).catch(errorHandler)
}

const deleteYears = (payload, responseHandler, errorHandler) => {
    return HTTP.delete(`/years/${payload.itemId}`).then(responseHandler).catch(errorHandler)
}

const getDistricts = (payload, responseHandler, errorHandler) => {
    return HTTP.get(`/federal-districts`).then(responseHandler).catch(errorHandler)
}

const addDistrict = (payload, responseHandler, errorHandler) => {
    return HTTP.post(`/federal-districts`, payload).then(responseHandler).catch(errorHandler)
}

const editDistrict = (payload, responseHandler, errorHandler) => {
    return HTTP.patch(`/federal-districts/${payload.id}`, payload).then(responseHandler).catch(errorHandler)
}

const deleteDistrict = (payload, responseHandler, errorHandler) => {
    return HTTP.delete(`/federal-districts/${payload.id}`).then(responseHandler).catch(errorHandler)
}

const getDistrictRegions = (payload, responseHandler, errorHandler) => {
    return HTTP.get(`/federal-districts/${payload.id}/region-years?year=${payload.year}`).then(responseHandler).catch(errorHandler)
}

const addDistrictRegions = (payload, responseHandler, errorHandler) => {
    return HTTP.post(`/federal-districts/${payload.id}/region-years`, payload).then(responseHandler).catch(errorHandler)
}

const editDistrictRegion = (payload, responseHandler, errorHandler) => {
    return HTTP.patch(`/federal-districts/${payload.districtId}/region-years/${payload.id}`, payload).then(responseHandler).catch(errorHandler)
}

const deleteDistrictRegion = (payload, responseHandler, errorHandler) => {
    return HTTP.delete(`/federal-districts/${payload.id}/region-years/${payload.regionId}`).then(responseHandler).catch(errorHandler)
}

const getRegionTypes = (payload, responseHandler, errorHandler) => {
    return HTTP.get(`/rr/types?year=${payload}`).then(responseHandler).catch(errorHandler)
}

const addRegionType = (payload, responseHandler, errorHandler) => {
    return HTTP.post(`/rr/types`, payload).then(responseHandler).catch(errorHandler)
}

const editRegionType = (payload, responseHandler, errorHandler) => {
    return HTTP.patch(`/rr/types/${payload.id}`, payload).then(responseHandler).catch(errorHandler)
}

const deleteRegionType = (payload, responseHandler, errorHandler) => {
    return HTTP.delete(`/rr/types/${payload.id}`).then(responseHandler).catch(errorHandler)
}

const getRegionSubtypes = (payload, responseHandler, errorHandler) => {
    return HTTP.get(`/rr/types/${payload.typeId}/subtypes`).then(responseHandler).catch(errorHandler)
}

const addRegionSubtype = (payload, responseHandler, errorHandler) => {
    return HTTP.post(`/rr/types/${payload.typeId}/subtypes`, payload).then(responseHandler).catch(errorHandler)
}

const editRegionSubtype = (payload, responseHandler, errorHandler) => {
    return HTTP.patch(`/rr/subtypes/${payload.id}`, payload).then(responseHandler).catch(errorHandler)
}

const deleteRegionSubtype = (payload, responseHandler, errorHandler) => {
    return HTTP.delete(`/rr/subtypes/${payload.id}`).then(responseHandler).catch(errorHandler)
}

const getGroups = (payload, responseHandler, errorHandler) => {
    return HTTP.get(`/rr/subtypes/${payload.subtypeId}/groups`).then(responseHandler).catch(errorHandler)
}

const addGroup = (payload, responseHandler, errorHandler) => {
    return HTTP.post(`/rr/subtypes/${payload.subtypeId}/groups`, payload).then(responseHandler).catch(errorHandler)
}

const editGroup = (payload, responseHandler, errorHandler) => {
    return HTTP.patch(`/rr/groups/${payload.id}`, payload).then(responseHandler).catch(errorHandler)
}

const deleteGroup = (payload, responseHandler, errorHandler) => {
    return HTTP.delete(`/rr/groups/${payload.id}`).then(responseHandler).catch(errorHandler)
}

const getIndicators = (payload, responseHandler, errorHandler) => {
    if (payload.regionId && payload.groupId) {
        return HTTP.get(`/rr/specs?type=${payload.type}&year=${payload.year}&regionId=${payload.regionId}&rrGroupId=${payload.groupId}`).then(responseHandler).catch(errorHandler)
    } else if (payload.regionId) {
        return HTTP.get(`/rr/specs?type=${payload.type}&year=${payload.year}&regionId=${payload.regionId}`).then(responseHandler).catch(errorHandler)
    } else if (payload.type === 'Base') {
        return HTTP.get(`/rr/specs?type=${payload.type}&year=${payload.year}&rrSpecBlockId=${payload.rrSpecBlockId}`).then(responseHandler).catch(errorHandler)
    } else if (payload.type === 'Calculated') {
        return HTTP.get(`/rr/specs?type=${payload.type}&year=${payload.year}&rrGroupId=${payload.rrGroupId}`).then(responseHandler).catch(errorHandler)
    } else {
        return HTTP.get(`/rr/specs?type=Base&year=${payload.year}`).then(responseHandler).catch(errorHandler)
    }
}

const getBaseBlocks = (payload, responseHandler, errorHandler) => {
    return HTTP.get(`/rr/spec-blocks?year=${payload.year}`).then(responseHandler).catch(errorHandler)
}

const addIndicators = (payload, responseHandler, errorHandler) => {
    return HTTP.post(`/rr/specs`, payload).then(responseHandler).catch(errorHandler)
}

const editIndicators = (payload, responseHandler, errorHandler) => {
    return HTTP.patch(`/rr/specs/${payload.id}`, payload).then(responseHandler).catch(errorHandler)
}

const deleteIndicators = (payload, responseHandler, errorHandler) => {
    return HTTP.delete(`/rr/specs/${payload.id}`).then(responseHandler).catch(errorHandler)
}

const addIndicatorsRegionValue = (payload, responseHandler, errorHandler) => {
    return HTTP.post(`/rr/specs/value`, payload).then(responseHandler).catch(errorHandler)
}

const getAlgorithms = (responseHandler, errorHandler) => {
    return HTTP.get(`/rr/algorithms`).then(responseHandler).catch(errorHandler)
}

const addAlgorithms = (payload, responseHandler, errorHandler) => {
    return HTTP.post(`/rr/algorithms`, payload).then(responseHandler).catch(errorHandler)
}

const editAlgorithms = (payload, responseHandler, errorHandler) => {
    return HTTP.patch(`/rr/algorithms/${payload.id}`, payload).then(responseHandler).catch(errorHandler)
}

const deleteAlgorithms = (payload, responseHandler, errorHandler) => {
    return HTTP.delete(`/rr/algorithms/${payload.id}`).then(responseHandler).catch(errorHandler)
}

export default {
    getYears,
    addYears,
    editYears,
    deleteYears,
    getDistricts,
    addDistrict,
    editDistrict,
    deleteDistrict,
    getDistrictRegions,
    addDistrictRegions,
    editDistrictRegion,
    deleteDistrictRegion,
    getRegionTypes,
    addRegionType,
    editRegionType,
    deleteRegionType,
    getRegionSubtypes,
    addRegionSubtype,
    editRegionSubtype,
    deleteRegionSubtype,
    getGroups,
    addGroup,
    editGroup,
    deleteGroup,
    getIndicators,
    getBaseBlocks,
    addIndicators,
    editIndicators,
    deleteIndicators,
    addIndicatorsRegionValue,
    getAlgorithms,
    addAlgorithms,
    editAlgorithms,
    deleteAlgorithms,
}