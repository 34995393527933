import { esgApi } from "@/api"

export default {
    state: {
        esg: [],
        esgId: {},
        esgCategories: [],
        esgTypes: [],
        esgSubtypes: []
    },

    getters: {
        getEsg: (state) => state.esg,
        getEsgId: (state) => state.esgId,
        getEsgCategories: (state) => state.esgCategories,
        getEsgTypes: (state) => state.esgTypes,
        getEsgSubtypes: (state) => state.esgSubtypes,
    },

    mutations: {
        setEsg(state, payload) {
            state.esg = payload
        },

        setEsgId(state, payload) {
            state.esgId = payload
        },

        setEsgCategories(state, payload) {
            state.esgCategories = payload
        },

        setEsgTypes(state, payload) {
            state.esgTypes = payload
        },

        setEsgSubtypes(state, payload) {
            state.esgSubtypes = payload
        },
    },

    actions: {
        esg(state , payload) {
            return new Promise((resolve, reject) => {
                esgApi.getEsg(payload).then((res) => {
                    // commit('setEsg', res.data.data)
                    resolve(res.data)
                }).catch((err) => {
                    reject(err)
                })
            })
        },

        esgId({ commit }, payload) {
            return new Promise((resolve, reject) => {
                esgApi.getEsgId(payload).then((res) => {
                    commit('setEsgId', res.data)
                    resolve(res.data)
                }).catch((err) => {
                    reject(err)
                })
            })
        },

        addEsg(state, payload) {
            return new Promise((resolve, reject) => {
                esgApi.addEsg(payload).then((res) => {
                    resolve(res.data)
                }).catch((err) => {
                    reject(err)
                })
            })
        },

        acceptEsg(state, payload) {
            return new Promise((resolve, reject) => {
                esgApi.acceptEsg(payload).then((res) => {
                    resolve(res.data)
                }).catch((err) => {
                    reject(err)
                })
            })
        },

        rejectEsg(state, payload) {
            return new Promise((resolve, reject) => {
                esgApi.rejectEsg(payload).then((res) => {
                    resolve(res.data)
                }).catch((err) => {
                    reject(err)
                })
            })
        },

        closeEsg(state, payload) {
            return new Promise((resolve, reject) => {
                esgApi.closeEsg(payload).then((res) => {
                    resolve(res.data)
                }).catch((err) => {
                    reject(err)
                })
            })
        },

        deleteEsg(state, payload) {
            return new Promise((resolve, reject) => {
                esgApi.deleteEsg(payload).then((res) => {
                    resolve(res.data)
                }).catch((err) => {
                    reject(err)
                })
            })
        },

        editEsg(state, payload) {
            return new Promise((resolve, reject) => {
                esgApi.editEsg(payload).then((res) => {
                    resolve(res.data)
                }).catch((err) => {
                    reject(err)
                })
            })
        },

        esgCategories({ commit }, payload) {
            return new Promise((resolve, reject) => {
                esgApi.getEsgCategories(payload).then((res) => {
                    commit('setEsgCategories', res.data)
                    resolve(res.data)
                }).catch((err) => {
                    reject(err)
                })
            })
        },

        changeEsgCategory(state, payload) {
            return new Promise((resolve, reject) => {
                esgApi.changeEsgCategory(payload).then((res) => {
                    resolve(res.data)
                }).catch((err) => {
                    reject(err)
                })
            })
        },

        addEsgCategory(state, payload) {
            return new Promise((resolve, reject) => {
                esgApi.addEsgCategory(payload).then((res) => {
                    resolve(res.data)
                }).catch((err) => {
                    reject(err)
                })
            })
        },

        deleteEsgCategory(state, payload) {
            return new Promise((resolve, reject) => {
                esgApi.deleteEsgCategory(payload).then((res) => {
                    resolve(res.data)
                }).catch((err) => {
                    reject(err)
                })
            })
        },

        esgTypes({ commit }, payload) {
            return new Promise((resolve, reject) => {
                esgApi.getEsgTypes(payload).then((res) => {
                    commit('setEsgTypes', res.data)
                    resolve(res.data)
                }).catch((err) => {
                    reject(err)
                })
            })
        },

        changeEsgType(state, payload) {
            return new Promise((resolve, reject) => {
                esgApi.changeEsgType(payload).then((res) => {
                    resolve(res.data)
                }).catch((err) => {
                    reject(err)
                })
            })
        },

        addEsgType(state, payload) {
            return new Promise((resolve, reject) => {
                esgApi.addEsgType(payload).then((res) => {
                    resolve(res.data)
                }).catch((err) => {
                    reject(err)
                })
            })
        },

        deleteEsgType(state, payload) {
            return new Promise((resolve, reject) => {
                esgApi.deleteEsgType(payload).then((res) => {
                    resolve(res.data)
                }).catch((err) => {
                    reject(err)
                })
            })
        },

        esgSubtypes({ commit }, payload) {
            return new Promise((resolve, reject) => {
                esgApi.getEsgSubtypes(payload).then((res) => {
                    commit('setEsgSubtypes', res.data)
                    resolve(res.data)
                }).catch((err) => {
                    reject(err)
                })
            })
        },

        changeEsgSubtype(state, payload) {
            return new Promise((resolve, reject) => {
                esgApi.changeEsgSubtype(payload).then((res) => {
                    resolve(res.data)
                }).catch((err) => {
                    reject(err)
                })
            })
        },

        addEsgSubtype(state, payload) {
            return new Promise((resolve, reject) => {
                esgApi.addEsgSubtype(payload).then((res) => {
                    resolve(res.data)
                }).catch((err) => {
                    reject(err)
                })
            })
        },

        deleteEsgSubtype(state, payload) {
            return new Promise((resolve, reject) => {
                esgApi.deleteEsgSubtype(payload).then((res) => {
                    resolve(res.data)
                }).catch((err) => {
                    reject(err)
                })
            })
        },
    }
}