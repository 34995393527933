import HTTP from '@/api/http'

const getEsg = (params, responseHandler, errorHandler) => {
    return HTTP.get(`/esg/orders`, {
        params: params,
    }).then(responseHandler).catch(errorHandler)
}

const getEsgId = (params, responseHandler, errorHandler) => {
    return HTTP.get(`/esg/orders/${params.id}`, ).then(responseHandler).catch(errorHandler)
}

const deleteEsg = (params, responseHandler, errorHandler) => {
    return HTTP.delete(`/esg/orders/${params.id}`).then(responseHandler).catch(errorHandler)
}

const editEsg = (params, responseHandler, errorHandler) => {
    return HTTP.patch(`/esg/orders/${params.id}`, params).then(responseHandler).catch(errorHandler)
}

const addEsg = (params, responseHandler, errorHandler) => {
    return HTTP.post(`/esg/orders`, params).then(responseHandler).catch(errorHandler)
}

const acceptEsg = (params, responseHandler, errorHandler) => {
    return HTTP.post(`/esg/orders/${params.id}/accept`).then(responseHandler).catch(errorHandler)
}

const rejectEsg = (params, responseHandler, errorHandler) => {
    return HTTP.post(`/esg/orders/${params.id}/reject`).then(responseHandler).catch(errorHandler)
}

const closeEsg = (params, responseHandler, errorHandler) => {
    return HTTP.post(`/esg/orders/${params.id}/close`).then(responseHandler).catch(errorHandler)
}

const getEsgCategories = (params, responseHandler, errorHandler) => {
    return HTTP.get(`/esg/categories`, ).then(responseHandler).catch(errorHandler)
}

const changeEsgCategory = (params, responseHandler, errorHandler) => {
    return HTTP.patch(`/esg/category/${params.id}`, params).then(responseHandler).catch(errorHandler)
}

const addEsgCategory = (params, responseHandler, errorHandler) => {
    return HTTP.post(`/esg/category`, params).then(responseHandler).catch(errorHandler)
}

const deleteEsgCategory = (params, responseHandler, errorHandler) => {
    return HTTP.delete(`/esg/category/${params.id}`, params).then(responseHandler).catch(errorHandler)
}

const getEsgTypes = (params, responseHandler, errorHandler) => {
    return HTTP.get(`/esg/types`, ).then(responseHandler).catch(errorHandler)
}

const changeEsgType = (params, responseHandler, errorHandler) => {
    return HTTP.patch(`/esg/type/${params.id}`, params).then(responseHandler).catch(errorHandler)
}

const addEsgType = (params, responseHandler, errorHandler) => {
    return HTTP.post(`/esg/type`, params).then(responseHandler).catch(errorHandler)
}

const deleteEsgType = (params, responseHandler, errorHandler) => {
    return HTTP.delete(`/esg/type/${params.id}`, params).then(responseHandler).catch(errorHandler)
}

const getEsgSubtypes = (params, responseHandler, errorHandler) => {
    return HTTP.get(`/esg/subtypes`, ).then(responseHandler).catch(errorHandler)
}

const changeEsgSubtype = (params, responseHandler, errorHandler) => {
    return HTTP.patch(`/esg/Subtype/${params.id}`, params).then(responseHandler).catch(errorHandler)
}

const addEsgSubtype = (params, responseHandler, errorHandler) => {
    return HTTP.post(`/esg/Subtype`, params).then(responseHandler).catch(errorHandler)
}

const deleteEsgSubtype = (params, responseHandler, errorHandler) => {
    return HTTP.delete(`/esg/Subtype/${params.id}`, params).then(responseHandler).catch(errorHandler)
}

export default {
    getEsg,
    getEsgId,
    deleteEsg,
    editEsg,
    addEsg,
    acceptEsg,
    rejectEsg,
    closeEsg,
    getEsgCategories,
    changeEsgCategory,
    addEsgCategory,
    deleteEsgCategory,
    getEsgTypes,
    changeEsgType,
    addEsgType,
    deleteEsgType,
    getEsgSubtypes,
    changeEsgSubtype,
    addEsgSubtype,
    deleteEsgSubtype,
}