import { eventsApi } from "@/api"

export default {
    state: {
        events: [],
        eventsId: [],
        eventUsers: [],
        deletedEventUsers: [],
        eventsDates: [],
    },

    getters: {
        getEvents: (state) => state.events,
        getEventsId: (state) => state.eventsId,
        getEventUsers: (state) => state.eventUsers,
        getEventsDates: (state) => state.eventsDates,
    },

    mutations: {
        setEvents(state, payload) {
            state.events = payload
        },
        setEventsId(state, payload) {
            state.eventsId = payload
        },
        setEventUsers(state, payload) {
            state.eventUsers = payload
        },
        setDeletedEventUsers(state, payload) {
            if (payload.val) {
                state.deletedEventUsers.push(+payload.id)
            } else {
                state.deletedEventUsers = state.deletedEventUsers.filter((val) => +val !== +payload.id)
            }
            console.log(state.deletedEventUsers)
        },
        setEventsDates(state, payload) {
            state.eventsDates = payload
        },
    },

    actions: {
        events(state, payload) {
            return new Promise((resolve, reject) => {
                eventsApi.getEvents(payload).then((res) => {
                    // commit('setEvents', res.data.data)
                    resolve(res.data)
                }).catch((err) => {
                    reject(err)
                })
            })
        },
        eventsId({commit}, payload) {
            return new Promise((resolve, reject) => {
                eventsApi.getEventsId(payload).then((res) => {
                    commit('setEventsId', res.data)
                    resolve(res.data)
                }).catch((err) => {
                    reject(err)
                })
            })
        },
        addEvent(state, payload) {
            return new Promise((resolve, reject) => {
                eventsApi.addEvent(payload).then((res) => {
                    resolve(res.data)
                }).catch((err) => {
                    reject(err)
                })
            })
        },
        editEvent(state, payload) {
            return new Promise((resolve, reject) => {
                eventsApi.editEvent(payload).then((res) => {
                    resolve(res.data)
                }).catch((err) => {
                    reject(err)
                })
            })
        },
        deleteEvent(state, payload) {
            return new Promise((resolve, reject) => {
                eventsApi.deleteEvent(payload).then((res) => {
                    resolve(res.data)
                }).catch((err) => {
                    reject(err)
                })
            })
        },
        eventUsers(state, payload) {
            return new Promise((resolve, reject) => {
                eventsApi.getEventUsers(payload).then((res) => {
                    // commit('setEventUsers', res.data.data)
                    resolve(res.data)
                }).catch((err) => {
                    reject(err)
                })
            })
        },
        registerUsersEvent(state, payload) {
            return new Promise((resolve, reject) => {
                eventsApi.registerUsersEvent(payload).then((res) => {
                    resolve(res.data)
                }).catch((err) => {
                    reject(err)
                })
            })
        },
        eventTakePart(state, payload) {
            return new Promise((resolve, reject) => {
                eventsApi.eventTakePart(payload).then((res) => {
                    resolve(res.data)
                }).catch((err) => {
                    reject(err)
                })
            })
        },
        eventCancelPart(state, payload) {
            return new Promise((resolve, reject) => {
                eventsApi.eventCancelPart(payload).then((res) => {
                    resolve(res.data)
                }).catch((err) => {
                    reject(err)
                })
            })
        },
        eventsDates({commit}, payload) {
            return new Promise((resolve, reject) => {
                eventsApi.getEventsDates(payload).then((res) => {
                    commit('setEventsDates', res.data.data)
                    resolve(res.data.data)
                }).catch((err) => {
                    reject(err)
                })
            })
        },
    }
}