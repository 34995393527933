import {adminRegionApi} from '@/api'

export default {
    state: {
        years: [],
        districts: [],
        districtRegions: [],
        regionTypes: [],
        regionSubtypes: [],
        groups: [],
        indicators: [],
        baseBlocks: [],
        paramsOptions: [],
        algorithms: []
    },
    getters: {
        getYears: (state) => state.years,
        getDistricts: (state) => state.districts,
        getDistrictRegions: (state) => state.districtRegions,
        getRegionTypes: (state) => state.regionTypes,
        getRegionSubtypes: (state) => state.regionSubtypes,
        getGroups: (state) => state.groups,
        getIndicators: (state) => state.indicators,
        getBaseBlocks: (state) => state.baseBlocks,
        getParamsOptions: (state) => state.paramsOptions,
        getAlgorithms: (state) => state.algorithms
    },
    mutations: {
        setYears(state, payload) {
            state.years = payload
        },
        setDistricts(state, payload) {
            state.districts = payload
        },
        setDistrictRegions(state, payload) {
            state.districtRegions = payload
        },
        setRegionTypes(state, payload) {
            state.regionTypes = payload
        },
        setRegionSubtypes(state, payload) {
            state.regionSubtypes = payload
        },
        setGroups(state, payload) {
            state.groups = payload
        },
        setIndicators(state, payload) {
            state.indicators = payload
        },
        setBaseBlocks(state, payload) {
            state.baseBlocks = payload
        },
        setParamsOptions(state, payload) {
            state.paramsOptions = payload
        },
        setAlgorithms(state, payload) {
            state.algorithms = payload
        }
    },
    actions: {
        years({ commit }) {
            return new Promise((resolve, reject) => {
                adminRegionApi.getYears().then((res) => {
                    resolve(res.data.data)
                    commit('setYears', res.data.data)
                }).catch((err) => {
                    reject(err)
                })
            })
        },

        addYears(state, payload) {
            return new Promise((resolve, reject) => {
                adminRegionApi.addYears(payload).then((res) => {
                    resolve(res.data.data)
                }).catch((err) => {
                    reject(err)
                })
            })
        },

        editYears(state, payload) {
            return new Promise((resolve, reject) => {
                adminRegionApi.editYears(payload).then((res) => {
                    resolve(res.data.data)
                }).catch((err) => {
                    reject(err)
                })
            })
        },

        deleteYears(state, payload) {
            return new Promise((resolve, reject) => {
                adminRegionApi.deleteYears(payload).then((res) => {
                    resolve(res.data.data)
                }).catch((err) => {
                    reject(err)
                })
            })
        },

        districts({ commit }) {
            return new Promise((resolve, reject) => {
                adminRegionApi.getDistricts().then((res) => {
                    resolve(res.data.data)
                    commit('setDistricts', res.data.data)
                }).catch((err) => {
                    reject(err)
                })
            })
        },

        addDistrict(state, payload) {
            return new Promise((resolve, reject) => {
                adminRegionApi.addDistrict(payload).then((res) => {
                    resolve(res.data.data)
                }).catch((err) => {
                    reject(err)
                })
            })
        },

        editDistrict(state, payload) {
            return new Promise((resolve, reject) => {
                adminRegionApi.editDistrict(payload).then((res) => {
                    resolve(res.data.data)
                }).catch((err) => {
                    reject(err)
                })
            })
        },

        deleteDistrict(state, payload) {
            return new Promise((resolve, reject) => {
                adminRegionApi.deleteDistrict(payload).then((res) => {
                    resolve(res.data.data)
                }).catch((err) => {
                    reject(err)
                })
            })
        },

        districtRegions({ commit }, payload) {
            return new Promise((resolve, reject) => {
                adminRegionApi.getDistrictRegions(payload).then((res) => {
                    resolve(res.data.data)
                    commit('setDistrictRegions', res.data.data)
                }).catch((err) => {
                    reject(err)
                })
            })
        },

        addDistrictRegions(state, payload) {
            return new Promise((resolve, reject) => {
                adminRegionApi.addDistrictRegions(payload).then((res) => {
                    resolve(res.data.data)
                }).catch((err) => {
                    reject(err)
                })
            })
        },

        editDistrictRegion(state, payload) {
            return new Promise((resolve, reject) => {
                adminRegionApi.editDistrictRegion(payload).then((res) => {
                    resolve(res.data.data)
                }).catch((err) => {
                    reject(err)
                })
            })
        },

        deleteDistrictRegion(state, payload) {
            return new Promise((resolve, reject) => {
                adminRegionApi.deleteDistrictRegion(payload).then((res) => {
                    resolve(res.data.data)
                }).catch((err) => {
                    reject(err)
                })
            })
        },

        regionTypes({ commit }, payload) {
            return new Promise((resolve, reject) => {
                adminRegionApi.getRegionTypes(payload).then((res) => {
                    resolve(res.data.data)
                    commit('setRegionTypes', res.data.data)
                }).catch((err) => {
                    reject(err)
                })
            })
        },

        addRegionType(state, payload) {
            return new Promise((resolve, reject) => {
                adminRegionApi.addRegionType(payload).then((res) => {
                    resolve(res.data.data)
                }).catch((err) => {
                    reject(err)
                })
            })
        },

        editRegionType(state, payload) {
            return new Promise((resolve, reject) => {
                adminRegionApi.editRegionType(payload).then((res) => {
                    resolve(res.data.data)
                }).catch((err) => {
                    reject(err)
                })
            })
        },

        deleteRegionType(state, payload) {
            return new Promise((resolve, reject) => {
                adminRegionApi.deleteRegionType(payload).then((res) => {
                    resolve(res.data.data)
                }).catch((err) => {
                    reject(err)
                })
            })
        },

        regionSubtypes({ commit }, payload) {
            return new Promise((resolve, reject) => {
                adminRegionApi.getRegionSubtypes(payload).then((res) => {
                    resolve(res.data.data)
                    commit('setRegionSubtypes', res.data.data)
                }).catch((err) => {
                    reject(err)
                })
            })
        },

        addRegionSubtype(state, payload) {
            return new Promise((resolve, reject) => {
                adminRegionApi.addRegionSubtype(payload).then((res) => {
                    resolve(res.data.data)
                }).catch((err) => {
                    reject(err)
                })
            })
        },

        editRegionSubtype(state, payload) {
            return new Promise((resolve, reject) => {
                adminRegionApi.editRegionSubtype(payload).then((res) => {
                    resolve(res.data.data)
                }).catch((err) => {
                    reject(err)
                })
            })
        },

        deleteRegionSubtype(state, payload) {
            return new Promise((resolve, reject) => {
                adminRegionApi.deleteRegionSubtype(payload).then((res) => {
                    resolve(res.data.data)
                }).catch((err) => {
                    reject(err)
                })
            })
        },

        groups({ commit }, payload) {
            return new Promise((resolve, reject) => {
                adminRegionApi.getGroups(payload).then((res) => {
                    resolve(res.data.data)
                    commit('setGroups', res.data.data)
                }).catch((err) => {
                    reject(err)
                })
            })
        },

        addGroup(state, payload) {
            return new Promise((resolve, reject) => {
                adminRegionApi.addGroup(payload).then((res) => {
                    resolve(res.data.data)
                }).catch((err) => {
                    reject(err)
                })
            })
        },

        editGroup(state, payload) {
            return new Promise((resolve, reject) => {
                adminRegionApi.editGroup(payload).then((res) => {
                    resolve(res.data.data)
                }).catch((err) => {
                    reject(err)
                })
            })
        },

        deleteGroup(state, payload) {
            return new Promise((resolve, reject) => {
                adminRegionApi.deleteGroup(payload).then((res) => {
                    resolve(res.data.data)
                }).catch((err) => {
                    reject(err)
                })
            })
        },

        indicators({ commit }, payload) {
            return new Promise((resolve, reject) => {
                adminRegionApi.getIndicators(payload).then((res) => {
                    resolve(res.data.data)
                    if(!payload.type) {
                        commit('setParamsOptions', res.data.data)
                    }else {
                        commit('setIndicators', res.data.data)
                    }
                }).catch((err) => {
                    reject(err)
                })
            })
        },

        baseBlocks({commit}, payload) {
            return new Promise((resolve, reject) => {
                adminRegionApi.getBaseBlocks(payload).then((res) => {
                    resolve(res.data.data)
                    commit('setBaseBlocks', res.data.data)
                }).catch((err) => {
                    reject(err)
                })
            })
        },

        addIndicators(state, payload) {
            return new Promise((resolve, reject) => {
                adminRegionApi.addIndicators(payload).then((res) => {
                    resolve(res.data.data)
                }).catch((err) => {
                    reject(err)
                })
            })
        },

        editIndicators(state, payload) {
            return new Promise((resolve, reject) => {
                adminRegionApi.editIndicators(payload).then((res) => {
                    resolve(res.data.data)
                }).catch((err) => {
                    reject(err)
                })
            })
        },

        deleteIndicators(state, payload) {
            return new Promise((resolve, reject) => {
                adminRegionApi.deleteIndicators(payload).then((res) => {
                    resolve(res.data.data)
                }).catch((err) => {
                    reject(err)
                })
            })
        },

        addIndicatorsRegionValue(state, payload) {
            return new Promise((resolve, reject) => {
                adminRegionApi.addIndicatorsRegionValue(payload).then((res) => {
                    resolve(res.data.data)
                }).catch((err) => {
                    reject(err)
                })
            })
        },

        algorithms({ commit }) {
            return new Promise((resolve, reject) => {
                adminRegionApi.getAlgorithms().then((res) => {
                    resolve(res.data.data)
                    commit('setAlgorithms', res.data.data)
                }).catch((err) => {
                    reject(err)
                })
            })
        },

        addAlgorithms(state, payload) {
            return new Promise((resolve, reject) => {
                adminRegionApi.addAlgorithms(payload).then((res) => {
                    resolve(res.data.data)
                }).catch((err) => {
                    reject(err)
                })
            })
        },

        editAlgorithms(state, payload) {
            return new Promise((resolve, reject) => {
                adminRegionApi.editAlgorithms(payload).then((res) => {
                    resolve(res.data.data)
                }).catch((err) => {
                    reject(err)
                })
            })
        },

        deleteAlgorithms(state, payload) {
            return new Promise((resolve, reject) => {
                adminRegionApi.deleteAlgorithms(payload).then((res) => {
                    resolve(res.data.data)
                }).catch((err) => {
                    reject(err)
                })
            })
        },
    }
}