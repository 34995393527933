import HTTP from '@/api/http'

const request = (payload, responseHandler, errorHandler) => {
    return HTTP.post('/support/request', payload).then(responseHandler).catch(errorHandler)
}
const requests = (payload, responseHandler, errorHandler) => {
    return HTTP.get('/support/requests', {
        params: {
            take: payload.take,
            skip: payload.skip,
            status: payload.status,
            moderatedBy: payload.moderatedBy
        }
    }).then(responseHandler).catch(errorHandler)
}
const requestGetWork = (payload, responseHandler, errorHandler) => {
    return HTTP.post(`/support/request/${payload.id}/start`, payload).then(responseHandler).catch(errorHandler)
}
const requestResolve = (payload, responseHandler, errorHandler) => {
    return HTTP.post(`/support/request/${payload.id}/resolve`, payload).then(responseHandler).catch(errorHandler)
}
export default {
    request,
    requests,
    requestGetWork,
    requestResolve
}