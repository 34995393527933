import { documentsApi } from "@/api"

export default {
    state: {
        documents: [],
    },

    getters: {
        getDocuments: (state) => state.documents,
    },

    mutations: {
        setDocuments(state, payload) {
            state.documents = payload
        },
    },

    actions: {
        documents({ commit }) {
            return new Promise((resolve, reject) => {
                documentsApi.getDocuments().then((res) => {
                    commit('setDocuments', res.data.data)
                    resolve(res.data.data)
                }).catch((err) => {
                    reject(err)
                })
            })
        },

        editDocument(state, payload) {
            return new Promise((resolve, reject) => {
                documentsApi.editDocument(payload).then((res) => {
                    resolve(res.data)
                }).catch((err) => {
                    reject(err)
                })
            })
        },

        addDocument(state, payload) {
            return new Promise((resolve, reject) => {
                documentsApi.addDocument(payload).then((res) => {
                    resolve(res.data)
                }).catch((err) => {
                    reject(err)
                })
            })
        },

        deleteDocument(state, payload) {
            return new Promise((resolve, reject) => {
                documentsApi.deleteDocument(payload).then((res) => {
                    resolve(res.data)
                }).catch((err) => {
                    reject(err)
                })
            })
        },
    }
}