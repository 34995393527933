<template>
  <div id="app">
    <div class="container">
      <HeaderAuth v-if="isAuth" />
      <Header v-else />
      <router-view />

<!--      <ui-alert v-model="visibleAlert" @input="logout">-->
<!--        <p class="ui-alert__title">Воспользуйтесь мобильным приложением</p>-->
<!--        <ui-button color="error" @click="logout">Понятно</ui-button>-->
<!--      </ui-alert>-->
    </div>
  </div>
</template>

<script>
import { getCookie } from "@/utils/cookie";
import {mapActions, mapGetters, mapMutations} from "vuex";

export default {
  components: {
    HeaderAuth: () => import('@/layouts/HeaderAuth'),
    Header: () => import('@/layouts/Header'),
    // UiButton: () => import('@/components/ui/UiButton'),
    // UiAlert: () => import('@/components/ui/UiAlert')
  },

  data: () => ({
    visibleAlert: false,
  }),

  watch: {
    getUser: {
      immediate: true,
      handler(val) {
        if(val && val.role === 'Active') {
          this.visibleAlert = true;
        }
      }
    }
  },

  computed: {
    ...mapGetters(['getUser']),

    isAuth() {
      return this.$store.getters.isAuth
    }
  },

  methods: {
    ...mapActions(['reqLogout']),
    ...mapMutations(['setAuth', 'setUserData']),

    logout() {
      this.reqLogout()
      this.visibleAlert = false
      this.$router.push('/login')
      this.setUserData(null)
    }
  },

  created() {
    if (getCookie('authToken')) {
      this.setAuth(true)
    }
  },
}
</script>

<style lang="scss">
  #app {
    background: #F5F5F5;
  }
  .highlight {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;

    &:after {
      content: ' ';
      width: 5px;
      height: 5px;
      border-radius: 50%;
      background-color: #E21F1F;
    }
  }
</style>
