import HTTP from '@/api/http'

const getEvents = (params, responseHandler, errorHandler) => {
    return HTTP.get(`/events`, {
        params: params,
    }).then(responseHandler).catch(errorHandler)
}

const getEventsId = (params, responseHandler, errorHandler) => {
    return HTTP.get(`/events/${params.id}`).then(responseHandler).catch(errorHandler)
}

const addEvent = (params, responseHandler, errorHandler) => {
    return HTTP.post(`/events`, params).then(responseHandler).catch(errorHandler)
}

const editEvent = (params, responseHandler, errorHandler) => {
    return HTTP.patch(`/events/${params.id}`, params).then(responseHandler).catch(errorHandler)
}

const deleteEvent = (params, responseHandler, errorHandler) => {
    return HTTP.delete(`/events/${params.id}`).then(responseHandler).catch(errorHandler)
}

const getEventUsers = (params, responseHandler, errorHandler) => {
    return HTTP.get(`/users`, {
        params
    }).then(responseHandler).catch(errorHandler)
}

const registerUsersEvent = (params, responseHandler, errorHandler) => {
    return HTTP.post(`/events/${params.id}/parts`, {
        userIds: params.users
    }).then(responseHandler).catch(errorHandler)
}

const eventTakePart = (params, responseHandler, errorHandler) => {
    return HTTP.post(`/events/${params.id}/take-part`, ).then(responseHandler).catch(errorHandler)
}

const eventCancelPart = (params, responseHandler, errorHandler) => {
    return HTTP.post(`/events/${params.id}/cancel-part`, ).then(responseHandler).catch(errorHandler)
}

const getEventsDates = (params, responseHandler, errorHandler) => {
    return HTTP.get(`/events/dates`, {
        params
    }).then(responseHandler).catch(errorHandler)
}

export default {
    getEvents,
    getEventsId,
    addEvent,
    editEvent,
    deleteEvent,
    getEventUsers,
    registerUsersEvent,
    eventTakePart,
    eventCancelPart,
    getEventsDates
}