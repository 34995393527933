import HTTP from '@/api/http'

const getUsers = (params, responseHandler, errorHandler) => {
    return HTTP.get(`/users`, {
        params: params,
    }).then(responseHandler).catch(errorHandler)
}

const getUserId = (params, responseHandler, errorHandler) => {
    return HTTP.get(`/users/${params}`).then(responseHandler).catch(errorHandler)
}

const userAccept = (params, responseHandler, errorHandler) => {
    return HTTP.post(`/users/${params}/accept`).then(responseHandler).catch(errorHandler)
}

const userDecline = (params, responseHandler, errorHandler) => {
    return HTTP.post(`/users/${params.id}/decline`,{
        roleRejectComment: params.roleRejectComment
    }).then(responseHandler).catch(errorHandler)
}
const addUser = (params, responseHandler, errorHandler) => {
    return HTTP.post(`/users`, params).then(responseHandler).catch(errorHandler)
}
const editUser = (params, responseHandler, errorHandler) => {
    return HTTP.patch(`/users/${params.id}`, params).then(responseHandler).catch(errorHandler)
}

const userConfHistory = (id, responseHandler, errorHandler) => {
    return HTTP.get(`/users/${id}/role-history`).then(responseHandler).catch(errorHandler)
}

export default {
    getUsers,
    getUserId,
    userAccept,
    userDecline,
    addUser,
    editUser,
    userConfHistory
}
