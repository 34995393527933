import HTTP from '@/api/http'

const getTypes = (responseHandler, errorHandler) => {
    return HTTP.get(`/types`).then(responseHandler).catch(errorHandler)
}
const getSubtypes = (id, responseHandler, errorHandler) => {
    return HTTP.get(`/types/${id}/subtypes`).then(responseHandler).catch(errorHandler)
}
const deleteType = (payload, responseHandler, errorHandler) => {
    console.log(payload);
    return HTTP.delete(`/types/${payload.subtype}/subtypes/${payload.id}`).then(responseHandler).catch(errorHandler)
}
const editType = (payload, responseHandler, errorHandler) => {
    return HTTP.patch(`/types/${payload.id}`, {
        name: payload.name,
        ord: payload.ord,
        nameInt: {
            en: payload.nameIntEn
        }
    }).then(responseHandler).catch(errorHandler)
}
const editSubtype = (payload, responseHandler, errorHandler) => {
    return HTTP.patch(`/types/${payload.typeId}/subtypes/${payload.id}`, {
        name: payload.name,
        ord: payload.ord,
        nameInt: {
            en: payload.nameIntEn
        },
        description: payload.description,
        descriptionInt: {
            en: payload.descriptionIntEn
        }
    }).then(responseHandler).catch(errorHandler)
}
const addSubtype = (payload, responseHandler, errorHandler) => {
    return HTTP.post(`/types/${payload.id}/subtypes`, {
        name: payload.name,
        ord: payload.ord
    }).then(responseHandler).catch(errorHandler)
}
const getSpecsTypes = (payload, responseHandler, errorHandler) => {
    return HTTP.get(`/specs/types`, {
        params: payload
    }).then(responseHandler).catch(errorHandler)
}
const addVariable = (payload, responseHandler, errorHandler) => {
    return HTTP.post(`/specs/types`, payload).then(responseHandler).catch(errorHandler)
}
const deleteVariable = (id, responseHandler, errorHandler) => {
    return HTTP.delete(`/specs/types/${id}`, id).then(responseHandler).catch(errorHandler)
}
const editVariable = (payload, responseHandler, errorHandler) => {
    return HTTP.patch(`/specs/types/${payload.id}`, payload).then(responseHandler).catch(errorHandler)
}
const getVariableValues = (payload, responseHandler, errorHandler) => {
    return HTTP.get(`/specs/types/${payload.id}/values`, {
        params: payload
    }).then(responseHandler).catch(errorHandler)
}
const editVariableValues = (payload, responseHandler, errorHandler) => {
    return HTTP.patch(`/specs/types/${payload.id}/values/${payload.value}`, {
        name: payload.name,
        ord: payload.ord,
        nameInt: {
            en: payload.nameIntEn
        }
    }).then(responseHandler).catch(errorHandler)
}
const addVariableValues = (payload, responseHandler, errorHandler) => {
    return HTTP.post(`/specs/types/${payload.id}/values`, {
        name: payload.name,
        ord: payload.ord
    }).then(responseHandler).catch(errorHandler)
}
const deleteVariableValues = (payload, responseHandler, errorHandler) => {
    return HTTP.delete(`/specs/types/${payload.id}/values/${payload.value}`, {
        name: payload.name
    }).then(responseHandler).catch(errorHandler)
}
const getColumns = (payload, responseHandler, errorHandler) => {
    console.log(payload)
    return HTTP.get(`/columns`, {
        params: payload
    }).then(responseHandler).catch(errorHandler)
}
const editColumns = (payload, responseHandler, errorHandler) => {
    return HTTP.patch(`/columns/${payload.id}`, {
        name: payload.name
    }).then(responseHandler).catch(errorHandler)
}
const deleteColumns =  (payload, responseHandler, errorHandler) => {
    return HTTP.delete(`/columns/${payload.id}`).then(responseHandler).catch(errorHandler)
}
const addColumns = (payload, responseHandler, errorHandler) => {
    return HTTP.post(`/columns`, payload).then(responseHandler).catch(errorHandler)
}
const addColumnNumber = (payload, responseHandler, errorHandler) => {
    return HTTP.post(`/columns/${payload.id}/number`, {
        orderSubtypeId: payload.orderSubtypeId,
        specValueId: payload.specValueId,
        amount: payload.amount
    }).then(responseHandler).catch(errorHandler)
}
const getFormulas = (payload, responseHandler, errorHandler) => {
    return HTTP.get(`/formulas`, {
        params: payload
    }).then(responseHandler).catch(errorHandler)
}
const addFormulas = (payload, responseHandler, errorHandler) => {
    return HTTP.post(`/formulas`, payload).then(responseHandler).catch(errorHandler)
}
const deleteFormula = (payload, responseHandler, errorHandler) => {
    return HTTP.delete(`/formulas/${payload.id}`, payload).then(responseHandler).catch(errorHandler)
}
const editFormula = (payload, responseHandler, errorHandler) => {
    return HTTP.patch(`/formulas/${payload.id}`, {
        name: payload.name
    }).then(responseHandler).catch(errorHandler)
}
const getFormulasActions = (payload, responseHandler, errorHandler) => {
    return HTTP.get(`/formulas/actions`, {
        params: payload
    }).then(responseHandler).catch(errorHandler)
}
const addFormulasAction = (payload, responseHandler, errorHandler) => {
    return HTTP.post(`/formulas/action`, payload).then(responseHandler).catch(errorHandler)
}
const deleteFormulasAction = (payload, responseHandler, errorHandler) => {
    return HTTP.delete(`/formulas/action/${payload.id}`).then(responseHandler).catch(errorHandler)
}
const editFormulasAction = (payload, responseHandler, errorHandler) => {
    return HTTP.patch(`/formulas/action/${payload.id}`, payload).then(responseHandler).catch(errorHandler)
}
const addFormulasElement = (payload, responseHandler, errorHandler) => {
    return HTTP.post(`/formulas/${payload.id}/element`, {
        action: payload.action,
        type: payload.type,
        specTypeId: payload.specTypeId,
        specColumnId: payload.specColumnId,
        amount: payload.amount
    }).then(responseHandler).catch(errorHandler)
}
const editFormulasElement = (payload, responseHandler, errorHandler) => {
    return HTTP.patch(`/formulas/${payload.id}/element/${payload.elementId}`, {
        action: payload.action,
        type: payload.type,
        specTypeId: payload.specTypeId,
        specColumnId: payload.specColumnId,
        amount: payload.amount
    }).then(responseHandler).catch(errorHandler)
}
const deleteFormulasElement = (payload, responseHandler, errorHandler) => {
    return HTTP.delete(`/formulas/${payload.id}/element/${payload.elementId}`).then(responseHandler).catch(errorHandler)
}
const getLanguages = (payload, responseHandler, errorHandler) => {
    return HTTP.get(`/support/languages`).then(responseHandler).catch(errorHandler)
}

const editLanguages = (payload, responseHandler, errorHandler) => {
    return HTTP.patch(`/support/languages/${payload.id}`, payload).then(responseHandler).catch(errorHandler)
}

const addLanguages = (payload, responseHandler, errorHandler) => {
    return HTTP.post(`/support/languages`, payload).then(responseHandler).catch(errorHandler)
}


export default {
    getTypes,
    getSubtypes,
    deleteType,
    editType,
    editSubtype,
    addSubtype,
    getSpecsTypes,
    addVariable,
    deleteVariable,
    editVariable,
    getVariableValues,
    editVariableValues,
    addVariableValues,
    deleteVariableValues,
    getColumns,
    editColumns,
    deleteColumns,
    addColumns,
    addColumnNumber,
    getFormulas,
    deleteFormula,
    getFormulasActions,
    addFormulas,
    editFormula,
    addFormulasAction,
    deleteFormulasAction,
    editFormulasAction,
    addFormulasElement,
    editFormulasElement,
    deleteFormulasElement,
    getLanguages,
    editLanguages,
    addLanguages
}